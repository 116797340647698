.wlb-popup-header {
  width: 350px;
}

.wlb-popup-header .ant-popover-title {
  padding: 0px;
}

.wlb-popup-header .ant-popover-inner-content {
  padding: 0px;
}