.class-modal {
  width: 815px !important;
  padding: 0 !important;
  background-color: #eff2f4;
  border-radius: 5px !important;
  overflow: hidden;
}

.spacer-modal {
  margin-bottom: 120px;
}