.ant-modal.confirmation-modal {
  width: 400px !important;
  font-family: 'Muli-Regular';
}

.button-wrapper {
  display: flex;
  flex-direction: row;
}

.ant-modal-title {
  text-align: center;
}

.ant-modal-header {
  background-color: white !important;
  border: none !important;
}

.ant-modal-body {
  text-align: center;
}

#delete-button {
  width: 100%;
  margin-left: 0;
  border-radius: 0;
  background-color: #014a62;
  font-size: 14px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.28;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
  border: none;
  font-family: Muli-Regular;
}

#cancel-button {
  width: 100%;
  border-radius: 0;
  background-color: #eff2f4;
  font-size: 14px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.28;
  letter-spacing: normal;
  text-align: center;
  color: #a9a8a8;
  border: none;
  font-family: Muli-Regular;
}
