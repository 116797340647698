.ant-modal-header {
  font-size: 18px !important;
  background-color: #eff2f4 !important;
}

.search-modal {
  height: 63px !important;
  font-size: 16px !important;
}

.search-modal {
  font-size: 16px !important;
}

.ant-input-search-icon {
  font-size: 21px;
  color: #014a62 !important;
}

.ant-input-search-icon:hover {
  color: #014a62 !important;
}

.component_render_follower_check_box {
  max-height: 370px;
  overflow-y: scroll;
}
