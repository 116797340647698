.vendor-product-list > .ant-select-arrow-icon {
    display: block !important;
}

.vendor-product-list > .ant-select-selection--single {
    height: 28px !important;
}

.vendor-product-list > .ant-select-selection__rendered {
    height: 20px !important;
}

.vendor-product-list > .ant-select-selection__rendered {
    line-height: 30px !important;
}
