.follower-check-box > .ant-checkbox-checked .ant-checkbox-inner {
  background-color: #014a62 !important;
  border-color: #014a62 !important;
}

.follower-check-box > .ant-checkbox-inner {
  width: 26px !important;
  height: 26px !important;
}

.follower-check-box > .ant-checkbox-inner::after {
  width: 8px !important;
  height: 14px !important;
}

.follower-check-box > .ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox:hover .ant-checkbox-inner,
.ant-checkbox-input:focus + .ant-checkbox-inner {
  border-color: #014a62 !important;
}
