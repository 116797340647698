.ant-select-selection__choice {
    color: #ffffff !important;
    background-color: #014a62 !important;
}

.ant-select-search__field {
    background-color: #ffffff !important;
    border-radius: 0px !important;
}

.ant-select-selection--multiple .ant-select-selection__choice__remove > * {
    color: #ffffff !important;
}

.ant-select-selection__choice {
    border-radius: 5px !important;
}
