@font-face {
  font-family: "Muli-Regular";
  src: url("../fonts/Muli/Muli-Regular.ttf");
}

@font-face {
  font-family: "Muli-Light";
  src: url("../fonts/Muli/Muli-Light.ttf");
}

@font-face {
  font-family: "Muli-SemiBold";
  src: url("../fonts/Muli/Muli-SemiBold.ttf");
}

@font-face {
  font-family: "Muli-Bold";
  src: url("../fonts/Muli/Muli-Bold.ttf");
}