.privacy-post-box > .ant-select-selection--single {
  height: 25px !important;
}

.privacy-post-box > .ant-select-arrow-icon {
  display: none !important;
}

.privacy-post-box > .ant-select-selection__rendered {
  height: 100%;
}

.privacy-post-box > .ant-select-selection-selected-value {
  max-width: 0%;
  width: 100%;
  height: 100%;
}

.privacy-post-box > .ant-select-selection__rendered {
  line-height: normal !important;
  margin-right: 0px !important;
}

.component_privacy_post_box_item {
  width: 210px;
  word-wrap: break-word;
  white-space: normal;
  display: inline-block;
  -ms-word-break: break-all;
  word-break: break-all;
  /* Non standard for WebKit */
  word-break: break-word;
  -webkit-hyphens: auto;
  -moz-hyphens: auto;
}

.component_privacy_post_box_item_title {
  white-space: nowrap;
}

.component_privacy_post_box_item_description {
  white-space: normal;
  line-height: 1.5;
  min-height: 40px;
  /* display: inline-block; */
}

.component_privacy_post_box_specific_follower {
  display: inline-block;
}
