.ant-select-selection-selected-value{
  text-align: left;
}

.ant-select-arrow .ant-select-arrow-icon{
  display: block !important;
}

.ant-select-selection.ant-select-selection--single{
  height: 30px !important;
}

.ant-select-selection__rendered{
  line-height: 30px !important;
}
